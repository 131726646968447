import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Lesson} from '../models/lesson.model';
import { HttpResponseErrorHandlerService } from '../../shared/services/http-response-error-handler.service';
import { EnrollmentCreateResponse } from '../models/enrollment-create-response.model';
import { EnrollmentDeleteResponse } from '../models/enrollment-delete-response.model';
import { LiveStream } from '../../shared/models/live-stream.model';
@Injectable({
  providedIn: 'root'
})
export class LessonsService {

  constructor(
    private httpService: HttpClient,
    private httpResponseErrorHandler: HttpResponseErrorHandlerService) {
  }

  getAllLessons(): Observable<Array<Lesson>> {
    return this.httpService.get(`${environment.api}/Lessons`)
      .pipe(
        map((lessons: any): Array<Lesson> => lessons),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }

  getLessonById(id: number): Observable<Lesson> {
    return this.httpService.get(`${environment.api}/Lessons/${id}`)
      .pipe(
        map((lesson: any): Lesson => lesson.data),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }

  enrollForLesson(id: number): Observable<EnrollmentCreateResponse> {
    return this.httpService.post(`${environment.api}/Lessons/${id}/Enrollment`, {})
      .pipe(
        map((enrollment: any): EnrollmentCreateResponse => enrollment.data)
      );
  }

  enrollForLessonLottery(id: number): Observable<any> {
    return this.httpService.post(`${environment.api}/Lessons/${id}/LotteryEnrollmentNew`, {})
  }

  cancelEnrollment(lessonId: number): Observable<EnrollmentDeleteResponse> {
    return this.httpService.delete(`${environment.api}/Lessons/${lessonId}/Enrollment`, {})
      .pipe(
        map((enrollment: any): EnrollmentDeleteResponse => enrollment.data)
      );
  }

  cancelLotteryEnrollment(lessonId: number): Observable<any> {
    return this.httpService.delete(`${environment.api}/Lessons/${lessonId}/LotteryEnrollmentNew`, {})
  }

  getLiveStream(id: number): Observable<LiveStream> {
    return this.httpService.get(`${environment.api}/Lessons/${id}/livestream`)
    .pipe(
      map((liveStream: any): LiveStream => liveStream)
    );
  }

  createLiveStreamLog(id: number): Observable<any> {
    return this.httpService.post(`${environment.api}/Lessons/${id}/livestream/log`, {});
  }
}
