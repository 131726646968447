<div class="alert alert-danger" *ngIf="submitted">
  {{'ValidationText.FillInAllRequiredFields' | transloco}}
</div>

<div class="alert alert-primary" *ngIf="!readonly">
  {{'MembershipApplication.Msg.PleaseFillInRequiredFieldsAndDocuments' | transloco}}
</div>

<div [formGroup]="formGroup">

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>{{'Documents' | transloco }}</label>
          <select class="form-control" formControlName="showDocumentsInPerson" (change)="changedShowDocumentsInPerson()">
            <option [ngValue]="false">{{'MembershipApplication.Msg.IUploadTheDocumentsByMyself' | transloco}}</option>
            <option [ngValue]="true">{{'MembershipApplication.Msg.IShowTheDocumentsAtTheCounter' | transloco}}</option>
          </select>
      </div>
    </div>
  </div>

  <div class="row mt-3" *ngIf="application?.onlineMembership?.passPhotoRequired">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">{{'PassportPhoto' | transloco}} *</label> <br>
          <app-details-view details="{{'MembershipApplication.Msg.UploadPassPhotoInfo' | transloco}} {{ 'PassPhoto.IntendedUse' | transloco }}"></app-details-view>
          <app-pass-photo-uploader 
            [ngClass]="{'is-invalid': submitted && formGroup.get('passportPhoto')?.errors}"
            formControlName="passportPhoto" [application]="application"></app-pass-photo-uploader>
          <span class="invalid-feedback" *ngIf="submitted && formGroup.get('passportPhoto')?.errors">
            {{'ValidationText.PleaseUploadFile' | transloco}}
        </span>
        </div>
      </div>
    </div>
  <ng-container *ngFor="let config of application.onlineMembership.configurations">
    <ng-container *ngIf="!application.showDocumentsInPerson && config.type === configType.Document;">
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="form-group">
              <label class="control-label">{{config.nameDe}} *</label> <br>
              <app-details-view *ngIf="config.descriptionDe" [details]="config.descriptionDe" ></app-details-view>
              <app-file-uploader [ngClass]="{'is-invalid': submitted && formGroup.get(config.id)!.errors}" formControlName="{{config.id}}" #uploader *ngIf="config.type === 1" [componentId]="config.id" [application]="application" [config]="config"></app-file-uploader>
              <span class="invalid-feedback" *ngIf="submitted && formGroup.get(config.id)!.errors">
                  {{'ValidationText.PleaseUploadFile' | transloco}}
              </span>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="row mt-3" *ngIf="!application.showDocumentsInPerson && config.type != configType.Document">
          <div [ngClass]="{'col-md-12': config.type === configType.Text, 'col-md-6': config.type === configType.DropdownList}">
            <div class="form-group">
              <label class="control-label">{{config.nameDe}} *</label> <br>

              <ng-container *ngIf="config.type === configType.Text">
                <app-details-view *ngIf="config.descriptionDe" [details]="config.descriptionDe"></app-details-view>

                <input type="text" class="form-control" formControlName="{{config.id}}"
                [ngClass]="{'is-invalid': submitted && formGroup.get(config.id)!.errors}"
              >
              </ng-container>


              <select class="form-control" *ngIf="config.type === configType.DropdownList" formControlName="{{config.id}}"
                [ngClass]="{'is-invalid': submitted && formGroup.get(config.id)!.errors}"
              >
                <option></option>
                <option [ngValue]="option" *ngFor="let option of getOptions(config.detailsJson)">{{option}}</option>
              </select>

              <span class="invalid-feedback" *ngIf="submitted && formGroup.get(config.id)!.errors">
                  {{'ValidationText.Required' | transloco}}
              </span>
            </div>
          </div>
        </div>
  </ng-container>

  <div class="row mt-3">
    <div class="col-md-6">
        <div class="form-group">
          <label class="control-label">{{'Message' | transloco}}</label>
          <textarea class="form-control" formControlName="commentFromApplicant"></textarea>
        </div>
    </div>
  </div>
</div>

<div class="well well-sm mt-3" style="text-align: right">
  <button class="btn btn-primary" (click)="submit()">{{'Forward' | transloco }}</button>
</div>