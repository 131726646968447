import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TournamentService } from '../services/tournament.service';
import { forkJoin } from 'rxjs';
import { TournamentDisplayService } from '../services/tournament-display.service';
import { GridService } from 'src/app/shared/ag-grid/grid.service';
import { TournamentDisplayEventGroupRankingModel } from '../models/tournament-display-event-group-ranking.model';
import { TournamentDisplayEventInformationModel } from '../models/tournament-display-event-information.model';

@Component({
    templateUrl: './tournament-public-event-group-ranking.component.html',
    styleUrls: ['./tournament-public-event-group-ranking.component.scss'],
    providers: [GridService]
})
export class TournamentPublicEventGroupRankingComponent implements OnInit {

    rankingModel!: TournamentDisplayEventGroupRankingModel;

    tournamentPlanId!: string;
    eventId!: string;
    groupId!: string;

    tournamentEventInformation!: TournamentDisplayEventInformationModel;

    constructor(
        private activatedRoute: ActivatedRoute,
        private tournamentService: TournamentService,
        private tournamentDisplayService: TournamentDisplayService,
    ) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.tournamentPlanId = params['tournamentPlanId'];
            this.eventId = params['eventId'];
            this.groupId = params['groupId'];
            this.loadData();
        });
    }

    loadData() {
        forkJoin({
            tournamentEventInformation: this.tournamentService.getDisplayEventInformation(this.tournamentPlanId, this.eventId),
            rankingModel: this.tournamentService.getEventGroupRanking(this.tournamentPlanId, this.eventId, this.groupId)
        }).subscribe({
            next: result => {
                this.tournamentEventInformation = result.tournamentEventInformation;
                if (this.tournamentEventInformation.additionLink) this.tournamentEventInformation.additionLink = this.tournamentDisplayService.getAdditionLink(this.tournamentEventInformation.additionLink);

                this.rankingModel = result.rankingModel;
            },
            error: error => {
            }
        });
    }
}