import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import { TranslocoService } from '@ngneat/transloco';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { StaffAppRoutingModule } from './staff-app-routing.module';
import { StaffAppMainLayoutComponent } from './staff-app-main-layout/staff-app-main-layout.component';
import { StaffKnowHowComponent } from './know-how/know-how.component';
import { StaffKnowHowDocumentsComponent } from './know-how/documents/documents.component';
import { StaffKnowHowFaqsComponent } from './know-how/faqs/faqs.component';
import { StaffNewsComponent } from './news/news.component';
import { StaffPlannerComponent } from './planner/planner.component';
import { StaffStatisticsComponent } from './statistics/statistics.component';
@NgModule({
    imports: [
        NgSelectModule,
        FormsModule,
        CommonModule,
        SharedModule,
        StaffAppRoutingModule
    ],
    declarations: [
        StaffAppMainLayoutComponent,
        StaffKnowHowComponent,
        StaffKnowHowDocumentsComponent,
        StaffKnowHowFaqsComponent,
        StaffNewsComponent,
        StaffPlannerComponent,
        StaffStatisticsComponent
    ],
    exports: [],
    providers: []
  })
export class StaffAppModule {
  constructor (translate: TranslocoService) {
    translate.setDefaultLang('de');
  }
}
