<div class="container-fluid">
  <div class="row">
    <div class="col offset-md-2 col-md-8">
      <h1>{{'MyRegistrations' | transloco}}</h1>
      <div *ngIf="openRegistrations && completedRegistrations">
        <div style="padding-top: 3rem;">
          <app-registrations-table *ngIf="openRegistrations"
                                  [title]="'Temporary' | transloco"
                                  [registrations]="openRegistrations">
          </app-registrations-table>
        </div>
        <div style="margin-top:5rem;">
          <app-registrations-table *ngIf="completedRegistrations"
                                  [title]="'Definite' | transloco"
                                  [showToggle]="true"
                                  [registrations]="completedRegistrations">
          </app-registrations-table>
        </div>
      </div>
      <div style="margin-bottom: 3rem;">
        <app-spinner *ngIf="!openRegistrations || !completedRegistrations" [margin]="'1rem auto'"></app-spinner>
      </div>
    </div>
  </div>
</div>