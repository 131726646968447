import { Component, OnInit } from '@angular/core';
import { TournamentService } from '../services/tournament.service';
import { ActivatedRoute, Params } from '@angular/router';
import { TournamentModel } from '../models/tournament.model';
import { TournamentDisplayEventGroupRankingModel } from '../models/tournament-display-event-group-ranking.model';
import { TournamentTeamDataModel } from '../models/tournament-team-data.model';
import { forkJoin } from 'rxjs';
import { TournamentEventModel } from '../models/tournament-event.model';
import { TournamentDisplayService } from '../services/tournament-display.service';
import { TournamentDisplayEventInformationModel } from '../models/tournament-display-event-information.model';

@Component({
  templateUrl: './tournament-team.component.html',
  styleUrls: ['./tournament-team.component.scss']
})
export class TournamentTeamComponent implements OnInit {

  tournamentPlanId!: string;
  teamId!: string;
  tournamentData?: TournamentModel;

  rankingModel!: TournamentDisplayEventGroupRankingModel;
  teamDataModel!: TournamentTeamDataModel;
  tournamentEventInformation!: TournamentDisplayEventInformationModel;
  secret!: string;

  constructor(
    private tournamentService: TournamentService,
    private tournamentDisplayService: TournamentDisplayService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // get params
    this.activatedRoute.params.subscribe((params: Params) => {

      this.tournamentPlanId = params['tournamentPlanId'];
      this.teamId = params['teamId'];

      // get secret
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.secret = params['secret'];
      });

      this.loadTournamentDate();
      this.loadRankingData();
    });
  }

  loadTournamentDate() {
    forkJoin({
      teamData: this.tournamentService.getTournamentTeamData(this.tournamentPlanId, this.teamId, this.secret),
      tournamentData: this.tournamentService.getTournamentById(this.tournamentPlanId)
    }).subscribe({
      next: (result) => {
        this.teamDataModel = result.teamData;
        this.tournamentData = result.tournamentData;
        this.loadTournamentEvent();
      },
      error: (error) => {
      }
    });
  }

  loadTournamentEvent() {
    if (this.teamDataModel) {
      this.tournamentService.getDisplayEventInformation(this.tournamentPlanId, this.teamDataModel.tournamentEventId).subscribe(x => {
        if (x.additionLink) x.additionLink = this.tournamentDisplayService.getAdditionLink(x.additionLink);
        this.tournamentEventInformation = x;
      })
    }
  }

  loadRankingData() {
    this.tournamentService.getEventGrupTeamRanking(this.tournamentPlanId, this.teamId).subscribe(x => {
      this.rankingModel = x;
    });
  }

}
