import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {LessonDetailsComponent} from './components/lesson-details/lesson-details.component';
import {lessonsRoutes} from './lessons.routes';
import {LessonsService} from './services/lessons.service';
import { LessonPropertiesDisplayComponent } from './components/lesson-properties-display/lesson-properties-display.component';
import { LessonsEnrollmentButtonComponent } from './components/lessons-enrollment-button/lessons-enrollment-button.component';
import { EnrollmentsService } from './services/enrollments.service';
import { MyLessonsOverviewComponent } from './components/my-lessons-overview/my-lessons-overview.component';
import { MyEnrollmentsTableComponent } from './components/my-lessons-overview/my-enrollments-table/my-enrollments-table.component';
import { LessonWithdrawButtonComponent } from './components/lesson-withdraw-button/lesson-withdraw-button.component';
import { EnrollmentContainerComponent } from './components/enrollment-container/enrollment-container.component';
import { EnrollmentMessagesComponent } from './components/enrollment-messages/enrollment-messages.component';
import { ShowLiveStreamButtonComponent } from './components/show-live-stream-button/show-live-stream-button.component';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { LotteryCountdownComponent } from './components/lottery-countdown/lottery-countdown.component';
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(lessonsRoutes),
  ],
  declarations: [
    LessonDetailsComponent,
    MyLessonsOverviewComponent,
    MyEnrollmentsTableComponent,
    LessonPropertiesDisplayComponent,
    LessonsEnrollmentButtonComponent,
    LessonWithdrawButtonComponent,
    EnrollmentContainerComponent,
    EnrollmentMessagesComponent,
    ShowLiveStreamButtonComponent,
    LoginButtonComponent,
    LotteryCountdownComponent
  ],
  providers: [
    LessonsService,
    EnrollmentsService
  ]
})
export class LessonsModule {
}
