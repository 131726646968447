import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { TournamentService } from '../services/tournament.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { TournamentDisplayEventModel } from '../models/tournament-display-event.model';
import { forkJoin } from 'rxjs';
import { TournamentEventModel } from '../models/tournament-event.model';
import { TournamentDisplayService } from '../services/tournament-display.service';
import { TournamentDisplayEventInformationModel } from '../models/tournament-display-event-information.model';

@Component({
    templateUrl: './tournament-public-event-groups.component.html',
    styleUrls: ['./tournament-public-event-groups.component.scss']
})
export class TournamentPublicEventGroupsComponent implements OnInit {

    tournamentPlanId!: string;
    eventId!: string;

    eventGroup!: TournamentDisplayEventModel;
    tournamentEventInformation!: TournamentDisplayEventInformationModel;

    constructor(
        private translocoService: TranslocoService,
        private activatedRoute: ActivatedRoute,
        private tournamentService: TournamentService,
        private tournamentDisplayService: TournamentDisplayService,
    ) {
    }
    ngOnInit() {
        this.translocoService.selectTranslate('Tournaments.Title').subscribe((x: any) => {
        });

        this.activatedRoute.params.subscribe((params: Params) => {
            this.tournamentPlanId = params['tournamentPlanId'];
            this.eventId = params['eventId'];
            this.loadTournamentEventGroups();
        });
    }

    loadTournamentEventGroups() {
        forkJoin({
            tournamentEventInformation: this.tournamentService.getDisplayEventInformation(this.tournamentPlanId, this.eventId),
            tournamentEventGroups: this.tournamentService.getEventGroups(this.tournamentPlanId, this.eventId)
        }).subscribe({
            next: result => {
                this.tournamentEventInformation = result.tournamentEventInformation;
                if (this.tournamentEventInformation.additionLink) this.tournamentEventInformation.additionLink = this.tournamentDisplayService.getAdditionLink(this.tournamentEventInformation.additionLink);

                var eventGroup = result.tournamentEventGroups;
                eventGroup.groups.sort((a, b) => a.number - b.number);
                eventGroup.groups.forEach(x => {
                    x.teams = x.teams.sort((a, b) => a.teamName.localeCompare(b.teamName));
                });
    
                this.eventGroup = eventGroup;
            },
            error: error => {
            }
        });
    }

}
