<div class="container" *ngIf="eventGroup">
    <div class="row">
        <div class="col-12 p-2 mb-3 bg-primary d-flex flex-column flex-md-row gap-1">
            <div class="flex-grow-1 m-0">
                <h3 class="text-light">Kategorie {{eventGroup.prefix}}</h3>
            </div>
            <div>
                <a class="btn btn-default w-100"
                    *ngIf="tournamentEventInformation.additionLink && tournamentEventInformation.additionLinkTitle"
                    [href]="tournamentEventInformation.additionLink">{{tournamentEventInformation.additionLinkTitle}}</a>
            </div>
            <div>
                <a class="btn btn-default w-100"
                    [routerLink]="'/tournaments/'+tournamentPlanId+'/events/'+eventId+'/games'">{{'Tournaments.LinksToPages.Games' | transloco}}
                </a>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3" *ngFor="let group of eventGroup.groups">
            <table class="table table-striped tournament-group">
                <thead>
                    <tr>
                        <th>Gruppe {{group.number}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let team of group.teams" class="tournament-group-row">
                        <td style="border:none;">{{team.teamName}}</td>
                    </tr>
                </tbody>
            </table>
            <a class="btn btn-default w-100"
                [routerLink]="'/tournaments/'+tournamentPlanId+'/events/'+eventId+'/groups/'+group.id+'/ranking'">Rangliste
                - Gruppe {{group.number}}</a>
        </div>
    </div>
</div>
