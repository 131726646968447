import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { TournamentModel } from "../models/tournament.model";
import { TournamentUpdateModel } from "../models/tournament-update.model";
import { TournamentListItemModel } from "../models/tournament-list-item.model";
import { TournamentTeamSettingsModel } from "../models/tournament-team-settings.model";
import { TournamentTeamSettingsUpdateModel } from "../models/tournament-team-settings-update.model";
import { TournamentEventModel } from "../models/tournament-event.model";
import { TournamentEventUpdateModel } from "../models/tournament-event-update.model";
import { TournamentEventListItemModel } from "../models/tournament-event-list-item.model";
import { TournamentFacilityModel } from "../models/tournament-facility.model";
import { TournamentSlotsCreateModel } from "../models/tournament-slots-create.model";
import { TournamentSlotValidationModel } from "../models/tournament-slot-validation.model";
import { TournamentSlotModel } from "../models/tournament-slot.model";
import { TournamentSlotDisplayDayModel } from "../models/tournament-slot-display-day.model";
import { TournamentSlotDeleteModel } from "../models/tournament-slot-delete.model";
import { TournamentSlotsCopyModel } from "../models/tournament-slots-copy.model";
import { TournamentEventGroupSettingsUpdateModel } from "../models/tournament-event-group-settings-update.model";
import { TournamentEventGameSettingsUpdateModel } from "../models/tournament-event-game-settings-update.model";
import { TournamentGroupAddRoomModel } from "../models/tournament-group-add-room.model";
import { TournamentGroupRoomListModel } from "../models/tournament-group-room-list.model";
import { TournamentAdministratorModel } from "../models/tournament-administrator.model";
import { TournamentEventDataMappingImFieldModel } from "../models/tournament-event-data-mapping-im-field.model";
import { TournamentEventDataMappingModel } from "../models/tournament-event-data-mapping.model";
import { TournamentGroupAddTeamModel } from "../models/tournament-group-add-team.model";
import { TournamentTeamDataModel } from "src/app/tournaments/models/tournament-team-data.model";
import { TournamentDisplayEventModel } from "../models/tournament-display-event.model";
import { TournamentEventGroupsModel } from "../models/tournament-event-groups.model";
import { TournamentGamePlanModel } from "../models/tournament-game-plan.model";
import { TournamentGameChangeSlotModel } from "../models/tournament-game-change-slot.model";
import { TournamentGameNewSlotModel } from "../models/tournament-game-new-slot.model";
import { TournamentGameResultModel } from "../models/tournament-game-result.model";
import { TournamentGameSetResultUpdateModel } from "../models/tournament-game-set-result-update.model";
import { TournamentTeamGamesModel } from "../models/tournament-team-games.model";
import { TournamentDisplayEventGroupRankingModel } from "../models/tournament-display-event-group-ranking.model";
import { TournamentEventGameLinksUpdateModel } from "../models/tournament-event-game-links-update.model";
import { TournamentDisplayGamePlansModel } from "../models/tournament-display-game-plans.model";
import { TournamentDisplayAvailableSlotsModel } from "../models/tournament-display-available-slots.model";
import { TournamentCaptainMailTextModel } from "../models/tournament-captain-mail-text.model";
import { TournamentCaptainMailTextUpdateModel } from "../models/tournament-captain-mail-text-update.model";
import { TournamentGamePlanStatusEnum } from "../enums/tournament-game-plan-status.enum";
import { TournamentGamesExchangeSlotsModel } from "../models/tournament-games-exchange-slots.model";
import { TournamentDisplayEventInformationModel } from "../models/tournament-display-event-information.model";

@Injectable({
    providedIn: 'root'
})
export class TournamentService {

    constructor(
        private httpClient: HttpClient,
    ) {
    }

    getAllTournaments(): Observable<TournamentListItemModel[]> {
        return this.httpClient.get<TournamentListItemModel[]>(`${environment.api}/tournaments`);
    }

    updateTournament(id: string, model: TournamentUpdateModel): Observable<TournamentModel> {
        return this.httpClient.put<TournamentModel>(`${environment.api}/tournaments/${id}`, model);
    }

    createTournament(model: TournamentUpdateModel): Observable<TournamentModel> {
        return this.httpClient.post<TournamentModel>(`${environment.api}/tournaments`, model);
    }

    getTournamentById(id: string): Observable<TournamentModel> {
        return this.httpClient.get<TournamentModel>(`${environment.api}/tournaments/${id}`);
    }

    deleteTournamentById(id: string): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${environment.api}/tournaments/${id}`);
    }

    // events
    getAllAvailableEventsForTournament(id: string): Observable<TournamentEventListItemModel[]> {
        return this.httpClient.get<TournamentEventListItemModel[]>(`${environment.api}/tournaments/${id}/events/availableEvents`);
    }

    getAllTournamentEvents(id: string): Observable<TournamentEventModel[]> {
        return this.httpClient.get<TournamentEventModel[]>(`${environment.api}/tournaments/${id}/events`);
    }

    getTournamentEventById(tournamentPlanId: string, id: string): Observable<TournamentEventModel> {
        return this.httpClient.get<TournamentEventModel>(`${environment.api}/tournaments/${tournamentPlanId}/events/${id}`);
    }

    addTournamentEvent(id: string, model: TournamentEventUpdateModel): Observable<boolean> {
        return this.httpClient.post<boolean>(`${environment.api}/tournaments/${id}/events`, model);
    }

    removeTournamentEvent(id: string, eventId: string): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${environment.api}/tournaments/${id}/events/${eventId}`);
    }

    // event group settings
    updateTournamentGroupSettings(tournamentPlanId: string, id: string, model: TournamentEventGroupSettingsUpdateModel): Observable<boolean> {
        return this.httpClient.put<boolean>(`${environment.api}/tournaments/${tournamentPlanId}/events/${id}/settings/group`, model);
    }

    // event game settings
    updateTournamentGameSettings(tournamentPlanId: string, id: string, model: TournamentEventGameSettingsUpdateModel): Observable<boolean> {
        return this.httpClient.put<boolean>(`${environment.api}/tournaments/${tournamentPlanId}/events/${id}/settings/game`, model);
    }

    // event game links
    updateTournamentGameLinks(tournamentPlanId: string, id: string, model: TournamentEventGameLinksUpdateModel): Observable<boolean> {
        return this.httpClient.put<boolean>(`${environment.api}/tournaments/${tournamentPlanId}/events/${id}/links/game`, model);
    }

    // event data mapping

    getTournamentEventMappingById(tournamentPlanId: string, id: string): Observable<TournamentEventDataMappingModel> {
        return this.httpClient.get<TournamentEventDataMappingModel>(`${environment.api}/tournaments/${tournamentPlanId}/events/${id}/dataMapping`);
    }

    updateTournamentEventMappingBy(tournamentPlanId: string, id: string, postModel: TournamentEventDataMappingModel): Observable<TournamentEventDataMappingModel> {
        return this.httpClient.post<TournamentEventDataMappingModel>(`${environment.api}/tournaments/${tournamentPlanId}/events/${id}/dataMapping`, postModel);
    }

    getTournamentEventMappingFieldsById(tournamentPlanId: string, id: string): Observable<TournamentEventDataMappingImFieldModel[]> {
        return this.httpClient.get<TournamentEventDataMappingImFieldModel[]>(`${environment.api}/tournaments/${tournamentPlanId}/events/${id}/dataMapping/fields`);
    }

    // teams settings
    getTournamentTeamsSettingsById(id: string): Observable<TournamentTeamSettingsModel> {
        return this.httpClient.get<TournamentTeamSettingsModel>(`${environment.api}/tournaments/${id}/settings/teams`);
    }

    updateTournamentTeamsSettings(id: string, model: TournamentTeamSettingsUpdateModel): Observable<TournamentTeamSettingsModel> {
        return this.httpClient.put<TournamentTeamSettingsModel>(`${environment.api}/tournaments/${id}/settings/teams`, model);
    }

    // teams
    getTournamentTeamData(tournamentPlanId: string, teamId: string, secret: string): Observable<TournamentTeamDataModel> {
        const requestOptions = {
            headers: new HttpHeaders({ 'secret': secret }),
        };

        return this.httpClient.get<TournamentTeamDataModel>(`${environment.api}/tournaments/${tournamentPlanId}/teams/${teamId}`, requestOptions);
    }

    getTournamentTeamGames(tournamentPlanId: string, teamId: string, secret: string): Observable<TournamentTeamGamesModel> {
        const requestOptions = {
            headers: new HttpHeaders({ 'secret': secret }),
        };

        return this.httpClient.get<TournamentTeamGamesModel>(`${environment.api}/tournaments/${tournamentPlanId}/teams/${teamId}/games`, requestOptions);
    }

    getTournamentTeamRefereeGames(tournamentPlanId: string, teamId: string, secret: string): Observable<TournamentTeamGamesModel> {
        const requestOptions = {
            headers: new HttpHeaders({ 'secret': secret }),
        };

        return this.httpClient.get<TournamentTeamGamesModel>(`${environment.api}/tournaments/${tournamentPlanId}/teams/${teamId}/refereeGames`, requestOptions);
    }

    // facilities
    getTournamentFacilities(id: string): Observable<TournamentFacilityModel[]> {
        return this.httpClient.get<TournamentFacilityModel[]>(`${environment.api}/tournaments/${id}/facilities`);
    }

    // slots
    getTournamentSlots(id: string): Observable<TournamentSlotModel[]> {
        return this.httpClient.get<TournamentSlotModel[]>(`${environment.api}/tournaments/${id}/slots`);
    }

    getTournamentSlotsDisplay(id: string): Observable<TournamentSlotDisplayDayModel[]> {
        return this.httpClient.get<TournamentSlotDisplayDayModel[]>(`${environment.api}/tournaments/${id}/slots/displayDays`);
    }

    createTournamentSlots(id: string, model: TournamentSlotsCreateModel): Observable<TournamentSlotModel[]> {
        return this.httpClient.post<TournamentSlotModel[]>(`${environment.api}/tournaments/${id}/slots`, model);
    }

    deleteTournamentSlots(id: string, model: TournamentSlotDeleteModel): Observable<boolean> {
        return this.httpClient.post<boolean>(`${environment.api}/tournaments/${id}/slots/delete`, model);
    }

    validateCopyTournamentSlots(id: string, model: TournamentSlotsCopyModel): Observable<TournamentSlotValidationModel[]> {
        return this.httpClient.post<TournamentSlotValidationModel[]>(`${environment.api}/tournaments/${id}/slots/copy/validate`, model);
    }

    copyTournamentSlots(id: string, model: TournamentSlotsCopyModel): Observable<boolean> {
        return this.httpClient.post<boolean>(`${environment.api}/tournaments/${id}/slots/copy`, model);
    }

    validateTournamentSlots(id: string, model: TournamentSlotsCreateModel): Observable<TournamentSlotValidationModel[]> {
        return this.httpClient.post<TournamentSlotValidationModel[]>(`${environment.api}/tournaments/${id}/slots/validate`, model);
    }

    getAvailableTournamentSlots(id: string): Observable<TournamentDisplayAvailableSlotsModel> {
        return this.httpClient.get<TournamentDisplayAvailableSlotsModel>(`${environment.api}/tournaments/${id}/slots/available`);
    }

    sendTeamCaptainEmail(tournamentPlanId: string): Observable<boolean> {
        return this.httpClient.post<boolean>(`${environment.api}/tournaments/${tournamentPlanId}/teams/sendCaptainMail`, null);
    }

    // groups
    getTournamentGroups(tournamentPlanId: string): Observable<TournamentEventGroupsModel> {
        return this.httpClient.get<TournamentEventGroupsModel>(`${environment.api}/tournaments/${tournamentPlanId}/groups`);
    }

    addTournamentGroupRoom(tournamentPlanId: string, groupId: string, model: TournamentGroupAddRoomModel): Observable<TournamentGroupRoomListModel> {
        return this.httpClient.post<TournamentGroupRoomListModel>(`${environment.api}/tournaments/${tournamentPlanId}/groups/${groupId}/rooms`, model);
    }

    deleteTournamentGroupRoom(tournamentPlanId: string, groupId: string, groupRoomId: string): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${environment.api}/tournaments/${tournamentPlanId}/groups/${groupId}/rooms/${groupRoomId}`);
    }

    triggerFormingGroups(tournamentPlanId: string): Observable<boolean> {
        return this.httpClient.post<boolean>(`${environment.api}/tournaments/${tournamentPlanId}/groups/form`, null);
    }

    deleteGroups(tournamentPlanId: string): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${environment.api}/tournaments/${tournamentPlanId}/groups`);
    }

    addTeamToGroup(tournamentPlanId: string, groupId: string, model: TournamentGroupAddTeamModel): Observable<TournamentGroupRoomListModel> {
        return this.httpClient.post<TournamentGroupRoomListModel>(`${environment.api}/tournaments/${tournamentPlanId}/groups/${groupId}/teams`, model);
    }

    // games
    getGamePlan(tournamentPlanId: string): Observable<TournamentGamePlanModel> {
        return this.httpClient.get<TournamentGamePlanModel>(`${environment.api}/tournaments/${tournamentPlanId}/games`);
    }

    triggerCreatingGames(tournamentPlanId: string): Observable<TournamentGamePlanStatusEnum> {
        return this.httpClient.post<TournamentGamePlanStatusEnum>(`${environment.api}/tournaments/${tournamentPlanId}/games/create`, null);
    }

    deleteGamePlans(tournamentPlanId: string): Observable<TournamentGamePlanStatusEnum> {
        return this.httpClient.delete<TournamentGamePlanStatusEnum>(`${environment.api}/tournaments/${tournamentPlanId}/games`);
    }

    getGamePlansStatus(tournamentPlanId: string): Observable<TournamentGamePlanStatusEnum> {
        return this.httpClient.get<TournamentGamePlanStatusEnum>(`${environment.api}/tournaments/${tournamentPlanId}/games/status`);
    }

    triggerSimulateGames(tournamentPlanId: string): Observable<boolean> {
        return this.httpClient.post<boolean>(`${environment.api}/tournaments/${tournamentPlanId}/games/simulate`, null);
    }

    getGamePlanChangeSlotOptions(tournamentPlanId: string, gameId: string): Observable<TournamentGameChangeSlotModel> {
        return this.httpClient.get<TournamentGameChangeSlotModel>(`${environment.api}/tournaments/${tournamentPlanId}/games/${gameId}/changeSlot`);
    }

    changeGamePlanSlot(tournamentPlanId: string, gameId: string, model: TournamentGameNewSlotModel): Observable<boolean> {
        return this.httpClient.post<boolean>(`${environment.api}/tournaments/${tournamentPlanId}/games/${gameId}/changeSlot`, model);
    }

    getGameResult(tournamentPlanId: string, gameId: string): Observable<TournamentGameResultModel> {
        return this.httpClient.get<TournamentGameResultModel>(`${environment.api}/tournaments/${tournamentPlanId}/games/${gameId}/result`);
    }

    setGameResult(tournamentPlanId: string, gameId: string, models: TournamentGameSetResultUpdateModel[]): Observable<TournamentGameResultModel> {
        return this.httpClient.post<TournamentGameResultModel>(`${environment.api}/tournaments/${tournamentPlanId}/games/${gameId}/result`, models);
    }

    deleteGameResults(tournamentPlanId: string, gameId: string): Observable<TournamentGameResultModel> {
        return this.httpClient.delete<TournamentGameResultModel>(`${environment.api}/tournaments/${tournamentPlanId}/games/${gameId}/result`);
    }

    getGameResultWithSecret(tournamentPlanId: string, gameId: string, secret: string): Observable<TournamentGameResultModel> {
        const requestOptions = {
            headers: new HttpHeaders({ 'secret': secret }),
        };

        return this.httpClient.get<TournamentGameResultModel>(`${environment.api}/tournaments/${tournamentPlanId}/games/${gameId}/teamResult`, requestOptions);
    }

    setGameResultWithSecret(tournamentPlanId: string, gameId: string, secret: string, models: TournamentGameSetResultUpdateModel[]): Observable<boolean> {
        const requestOptions = {
            headers: new HttpHeaders({ 'secret': secret }),
        };

        return this.httpClient.post<boolean>(`${environment.api}/tournaments/${tournamentPlanId}/games/${gameId}/teamResult`, models, requestOptions);
    }

    // captain mail
    getCaptainMailText(tournamentPlanId: string): Observable<TournamentCaptainMailTextModel> {
        return this.httpClient.get<TournamentCaptainMailTextModel>(`${environment.api}/tournaments/${tournamentPlanId}/captainMailText`);
    }

    updateCaptainMailText(tournamentPlanId: string, model: TournamentCaptainMailTextUpdateModel): Observable<TournamentCaptainMailTextModel> {
        return this.httpClient.post<TournamentCaptainMailTextModel>(`${environment.api}/tournaments/${tournamentPlanId}/captainMailText`, model);
    }

    // administrators
    getAdministrators(tournamentPlanId: string): Observable<TournamentAdministratorModel[]> {
        return this.httpClient.get<TournamentAdministratorModel[]>(`${environment.api}/tournaments/${tournamentPlanId}/administrators/list`);
    }

    getPossibleAdministrators(tournamentPlanId: string, personFilter: string): Observable<TournamentAdministratorModel[]> {
        return this.httpClient.get<TournamentAdministratorModel[]>(`${environment.api}/tournaments/${tournamentPlanId}/administrators?filter=${personFilter}`);
    }

    addAdministrators(tournamentPlanId: string, personId: number): Observable<boolean> {
        return this.httpClient.post<boolean>(`${environment.api}/tournaments/${tournamentPlanId}/administrators`, personId);
    }

    removeAsAdministrator(tournamentPlanId: string, personId: number): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${environment.api}/tournaments/${tournamentPlanId}/administrators/${personId}`);
    }

    // event display public
    getEventGroups(tournamentPlanId: string, eventId: string): Observable<TournamentDisplayEventModel> {
        return this.httpClient.get<TournamentDisplayEventModel>(`${environment.api}/tournaments/${tournamentPlanId}/events/${eventId}/groups`);
    }

    getEventGroupRanking(tournamentPlanId: string, eventId: string, groupId: string): Observable<TournamentDisplayEventGroupRankingModel> {
        return this.httpClient.get<TournamentDisplayEventGroupRankingModel>(`${environment.api}/tournaments/${tournamentPlanId}/events/${eventId}/groups/${groupId}/ranking`);
    }

    getEventGrupTeamRanking(tournamentPlanId: string, teamId: string): Observable<TournamentDisplayEventGroupRankingModel> {
        return this.httpClient.get<TournamentDisplayEventGroupRankingModel>(`${environment.api}/tournaments/${tournamentPlanId}/teams/${teamId}/ranking`);
    }

    getDisplayEventGames(tournamentPlanId: string, eventId: string): Observable<TournamentDisplayGamePlansModel> {
        return this.httpClient.get<TournamentDisplayGamePlansModel>(`${environment.api}/tournaments/${tournamentPlanId}/events/${eventId}/gamePlans`);
    }

    updateTournamentGamesSlots(tournamentPlanId: string, model: TournamentGamesExchangeSlotsModel){
        return this.httpClient.put<boolean>(`${environment.api}/tournaments/${tournamentPlanId}/games/exchangeSlots`, model);
    }

    getDisplayEventInformation(tournamentPlanId: string, eventId: string): Observable<TournamentDisplayEventInformationModel> {
        return this.httpClient.get<TournamentDisplayEventInformationModel>(`${environment.api}/tournaments/${tournamentPlanId}/events/${eventId}/information`);
    }
}
