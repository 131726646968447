import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserAuthorizationService } from './security/user-authorization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  // @ViewChild(ReloadModalComponent) myModal: ReloadModalComponent;

  collapsedSidebar: boolean = false;
  showScreen = false;
  isAdminView = false;
  showFooter = true;

  // public toasterconfig: ToasterConfig =
  //   new ToasterConfig({
  //     tapToDismiss: true,
  //     timeout: 4000
  //   });

  constructor(private location: Location,
    private router: Router,
    private authService: UserAuthorizationService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {

    // Configuration settings to handle sign-in/silent-renew error logging
    localStorage.setItem('origin_url', window.location.origin);
    localStorage.setItem('origin_api_url', environment.api);

    const url = location.path().split('/');
    if (url[1] === 'screen' || url[1] === 'information-screen' || url[1] === 'occupancyplanview') {
      this.showScreen = true;
    }

    if (window.location.href.indexOf('?postLogout=true') > 0) {
      this.authService.signoutRedirectCallback().then(() => {
        const returnUrl = `${environment.identity.authority}/account/loggedout`;
        window.location.href = returnUrl;
      });
    }
  }

  // from: https://toddmotto.com/dynamic-page-titles-angular-2-router-events
  ngOnInit() {

    this.router.events.subscribe(value => {
      this.isAdminView = this.router.url.toString().toLowerCase().includes('/office') ||  this.router.url.toString().toLowerCase().includes('/tournaments/admin') || this.router.url.toString().toLowerCase().includes('/staff');
      this.showFooter = !this.router.url.toString().toLowerCase().startsWith('/handheldscanner');
    }
    );

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((event) => {
        // set page title if provided by the routing data
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(`${title} - Online-Schalter`);
        }
      });
  }

  toggleCollapse(collapsedState: boolean) {
    this.collapsedSidebar = collapsedState;
  }
}
