import { Injectable } from '@angular/core';
import { Lesson } from '../models/lesson.model';
import * as moment from 'moment';
import { UserAuthorizationService } from 'src/app/security/user-authorization.service';
import { BehaviorSubject } from 'rxjs';
import { EnrollmentStateProperties } from '../models/enrollment-state-properties.model';
import { RequiredSkill } from 'src/app/events/required-skill.model';
import { Membership } from 'src/app/memberships/models';
import { MembershipState } from 'src/app/memberships/membership-state.enum';
import { CurrentEnrollment } from '../models/current-enrollment.model';
import { EnrollmentStatus } from '../enums/enrollment-status.enum';
import { LessonStatusEnum } from '../enums/lesson-status.enum';
import { DateHelperService } from 'src/app/shared/services/date-helper.service';
import { EnrollmentLotteryModel } from '../models/enrollment-create-response.model';
import { EnrollmentLotteryStateNewModel } from '../models/enrollment-lottery-state-new.model';
import { LotteryHeaderStatusEnum } from '../enums/lottery-header-status.enum';

@Injectable()
export class EnrollmentStateService {

    private lesson!: Lesson;
    private memberships!: Membership[];
    private missingSkills!: RequiredSkill[];
    private currentEnrollment!: CurrentEnrollment | null;
    private currentEnrollmentLottery: EnrollmentLotteryModel | null = null;
    private currentEnrollmentLotteryStateNew!: EnrollmentLotteryStateNewModel | null;

    private enrollmentStateProperties = new BehaviorSubject<EnrollmentStateProperties | null>(null);

    enrollmentStateProperties$ = this.enrollmentStateProperties.asObservable();

    constructor(private userAuthorizationService: UserAuthorizationService, private dateHelperService: DateHelperService) { }

    setData(lesson: Lesson, missingSkills: RequiredSkill[], memberships: Membership[], currentEnrollment: CurrentEnrollment | null, currentEnrollmentLotteryStateNew: EnrollmentLotteryStateNewModel | null): void {
        this.lesson = lesson;
        this.missingSkills = missingSkills;
        this.memberships = memberships;
        this.currentEnrollment = currentEnrollment;
        this.currentEnrollmentLotteryStateNew = currentEnrollmentLotteryStateNew;
        this.setEnrollmentStateProperties();
    }

    setCurrentEnrollmentLottery(enrollmentLottery: EnrollmentLotteryModel | null): void {
        this.currentEnrollmentLottery = enrollmentLottery;
    };

    setCurrentEnrollmentLotteryStateNew(enrollmentLotteryStateNew: EnrollmentLotteryStateNewModel | null): void {
        this.currentEnrollmentLotteryStateNew = enrollmentLotteryStateNew;
    }

    public userHasValidMembershipForLesson(memberships: Membership[], lessonStartDate: Date): boolean {
        let hasValidMembershipForStartDate = false;
        
        memberships?.forEach(x => {
          if (x.status === MembershipState.Completed
            && moment(lessonStartDate).startOf('day').isSameOrBefore(x.validUntil)
            && moment(lessonStartDate).startOf('day').isSameOrAfter(x.validFrom)) {
                hasValidMembershipForStartDate = true;
          }
        });
    
        return hasValidMembershipForStartDate;
      }

    setEnrollmentStateProperties(): void {
        const properties: EnrollmentStateProperties = {
            hasSeats: this.hasSeats,
            withdrawIsExpired: this.withdrawIsExpired,
            enrollmentIsExpired: this.enrollmentIsExpired,
            inLotteryTimeFrame: this.inLotteryTimeFrame,
            lessonIsActive: this.lessonIsActive,
            translateParams: this.translateParams,
            enrollmentIsOpen: this.enrollmentIsOpen,
            lotteryEnrollmentIsOpen: this.lotteryEnrollmentIsOpen,
            userHasValidMembershipForLesson: this.userHasValidMembershipForLesson(this.memberships, this.lesson.starts),
            userCanEnroll: this.userCanEnroll(this.memberships, this.lesson, this.missingSkills),
            userCanEnrollToLottery: this.userCanEnrollToLottery(this.memberships, this.lesson, this.missingSkills, this.currentEnrollmentLotteryStateNew!),
            userIsEnrolled: this.userIsEnrolled,
            userIsEnrolledToLottery: this.userIsEnrolledToLottery,
            currentEnrollment: this.currentEnrollment,
            currentEnrollmentLotteryStateNew: this.currentEnrollmentLotteryStateNew
        };
        
        this.enrollmentStateProperties.next(properties);
    }

       /**
   * Sets the flag indicating whether the user is allowed to enroll in the lesson.
   * The user must have valid memberships, meet skill requirements, and the lesson must be active,
   * have available seats, and not expired.
   */
  public userCanEnroll(memberships: Membership[], lesson: Lesson, missingSkills: RequiredSkill[]): boolean {

    const alreadyEnrolled = false;
    if (memberships || lesson.externeAnmeldung) {

      return missingSkills.length === 0 &&
        !this.userIsEnrolled &&
        this.lessonIsActive &&
        !this.enrollmentIsExpired &&
        this.hasSeats &&
        !alreadyEnrolled &&
        (this.userHasValidMembershipForLesson(memberships, lesson.starts) || lesson.externeAnmeldung)
    }

    return false;
  }

    public userCanEnrollToLottery(memberships: Membership[], lesson: Lesson, missingSkills: RequiredSkill[], currentEnrollmentLotteryStateNew: EnrollmentLotteryStateNewModel): boolean {
        const alreadyEnrolled = false;
        if (memberships || currentEnrollmentLotteryStateNew) {

            if (missingSkills.length !== 0) return false;
            if (this.userIsEnrolledToLottery) return false;
            if (!this.inLotteryTimeFrame) return false;
            if (!this.lessonIsActive) return false;
            if (this.currentEnrollmentLotteryStateNew?.lotteryHeaderDetails && this.currentEnrollmentLotteryStateNew?.lotteryHeaderDetails?.lotteryStatus !== LotteryHeaderStatusEnum.Open) {
                return false;
            }
            if (!this.hasSeats) return false;
            if (alreadyEnrolled) return false;
            if (!this.userHasValidMembershipForLesson(memberships, lesson.starts) 
                && currentEnrollmentLotteryStateNew.lotteryHeaderDetails?.lotteryStatus !== LotteryHeaderStatusEnum.Open){
                return false;
            }
            return true;          
        }
        return false;
    }

    get userIsEnrolled(): boolean {
        return this.currentEnrollment?.status === EnrollmentStatus.Completed
    }

    get hasSeats(): boolean {
        return (this.lesson.participantsMax - this.lesson.participantCount) > 0 || this.lesson.participantsMax === null;
    }

    get withdrawIsExpired(): boolean {
        return moment(this.lesson.cancelationUntil).isBefore(moment());
    }

    get enrollmentIsExpired(): boolean {
        return moment(this.lesson.enrollmentUntil).isBefore(moment());
    }

    get inLotteryTimeFrame(): boolean {
        return this.currentEnrollmentLotteryStateNew?.inLotteryTimeFrame!;
    }

    get lessonIsActive(): boolean {
        return this.lesson.status === LessonStatusEnum.Active || this.canPrepareLesson(this.lesson.status);
    }

    get enrollmentIsOpen(): boolean {
        const currentDate = new Date();
        currentDate.setMilliseconds(0);
        const enrollmentFromDate = new Date(this.lesson.enrollmentFrom);
        return this.dateHelperService.isAfter(currentDate, enrollmentFromDate) || this.dateHelperService.isEqual(currentDate, enrollmentFromDate);
    }

    get lotteryEnrollmentIsOpen(): boolean {
        return this.currentEnrollmentLotteryStateNew?.lotteryHeaderDetails?.lotteryStatus === LotteryHeaderStatusEnum.Open;
    }

    get userIsEnrolledToLottery(): boolean {
        return this.currentEnrollmentLotteryStateNew?.inLottery ?? false;
    }

    get translateParams(): any {
        return {
            'registrationFrom': moment(this.lesson.enrollmentFrom).format('DD.MM.YYYY HH:mm:ss'),
            'cancelationUntil': moment(this.lesson.cancelationUntil).format('DD.MM.YYYY HH:mm'),
            'lessonTitle': this.lesson.title
        };
    }

    getLotteryDurationInSeconds(): number {

        if (this.currentEnrollmentLottery == null || this.currentEnrollmentLottery?.lotteryTo == null) {
            return 0;
        }
        
        const lotteryToDate = new Date(this.currentEnrollmentLottery.lotteryTo);
        const targetDate = this.dateHelperService.addSeconds(lotteryToDate, this.currentEnrollmentLottery.lotteryResultWaitTimeSeconds);
        const differenceInSeconds = this.dateHelperService.differenceInSeconds(targetDate, new Date());
        
        if (differenceInSeconds < 0) {
            return 0;
        }

        return differenceInSeconds;
    }

    private canPrepareLesson(status: number): boolean {
        const currentUser = this.userAuthorizationService.getUser();
        return status === LessonStatusEnum.InPreparation &&
          (
            currentUser.hasRoleAdmin() ||
            currentUser.hasRoleOffice() ||
            currentUser.hasRoleHspl()
          );
      }
}
