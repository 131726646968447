import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StaffNewsComponent } from './news/news.component';
import { StaffKnowHowComponent } from './know-how/know-how.component';
import { StaffKnowHowDocumentsComponent } from './know-how/documents/documents.component';
import { StaffKnowHowFaqsComponent } from './know-how/faqs/faqs.component';
import { StaffStatisticsComponent } from './statistics/statistics.component';
import { StaffPlannerComponent } from './planner/planner.component';
import { StaffAppMainLayoutComponent } from './staff-app-main-layout/staff-app-main-layout.component';

const routes: Routes = [
    {
        path: '',
        component: StaffNewsComponent
    },
    {
      path: 'news',
      component: StaffNewsComponent
    },
    {
      path: 'know-how',
      component: StaffKnowHowComponent
    },
    {
      path: 'know-how/documents',
      component: StaffKnowHowDocumentsComponent
    },
    {
      path: 'know-how/faqs',
      component: StaffKnowHowFaqsComponent
    },
    {
      path: 'statistics',
      component: StaffStatisticsComponent
    },
    {
      path: 'planner',
      component: StaffPlannerComponent
    }
  ];
  
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
})
export class StaffAppRoutingModule { }  